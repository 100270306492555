import { api } from "@/api";
import { ActionContext } from "vuex";
import {
  IUserProfileCreate,
  IUserProfileUpdate,
  IProductCostModelCreate,
  IProductCostModelUpdate,
  ICostModelCreate,
  IUserCostModelCreate,
  ICostModelUpdate,
  IUserCostModelUpdate,
  IUserLicenseCreate,
} from "@/interfaces";
import { State } from "../state";
import { AdminState } from "./state";
import { getStoreAccessors } from "typesafe-vuex";
import { commitSetUsers, commitSetUser } from "./mutations";
import { dispatchCheckApiError } from "../main/actions";
import { commitAddNotification, commitRemoveNotification } from "../main/mutations";

type MainContext = ActionContext<AdminState, State>;

export const actions = {
  async actionGetUser(context: MainContext, { id }: { id: number }) {
    try {
      const response = await api.getUser(context.rootState.main.token, id);
      if (response && response.data) {
        return response.data;
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionGetUsers(context: MainContext) {
    try {
      const response = await api.getUsers(context.rootState.main.token);
      if (response) {
        commitSetUsers(context, response.data);
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionUpdateUser(
    context: MainContext,
    payload: { id: number; user: IUserProfileUpdate },
  ) {
    try {
      const loadingNotification = { content: "saving", showProgress: true };
      commitAddNotification(context, loadingNotification);
      const response = (
        await Promise.all([
          api.updateUser(context.rootState.main.token, payload.id, payload.user),
          await new Promise<void>((resolve, _) => setTimeout(() => resolve(), 500)),
        ])
      )[0];
      commitSetUser(context, response.data);
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, {
        content: "User successfully updated",
        color: "success",
      });
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionCreateUser(context: MainContext, payload: IUserProfileCreate) {
    try {
      const loadingNotification = { content: "saving", showProgress: true };
      commitAddNotification(context, loadingNotification);
      const response = (
        await Promise.all([
          api.createUser(context.rootState.main.token, payload),
          await new Promise<void>((resolve, _) => setTimeout(() => resolve(), 500)),
        ])
      )[0];
      commitSetUser(context, response.data);
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, {
        content: "User successfully created",
        color: "success",
      });
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionGetUserLicensesByUser(
    context: MainContext,
    { userId }: { userId: number },
  ) {
    try {
      const response = await api.getUserLicensesByUser(
        context.rootState.main.token,
        userId,
      );
      if (response && response.data) {
        return response.data;
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionGetUsageCostsStatisticsByUser(
    context: MainContext,
    {
      userId,
      startDate,
      endDate,
    }: { userId: number; startDate: string; endDate: string },
  ) {
    try {
      const response = await api.getUsageCostsStatisticsByUser(
        context.rootState.main.token,
        startDate,
        endDate,
        userId,
      );
      if (response && response.data) {
        return response.data;
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionGetComputeJobsByUser(
    context: MainContext,
    {
      userId,
      startDate,
      endDate,
    }: { userId: number; startDate: string; endDate: string },
  ) {
    try {
      const response = await api.getComputeJobsByUser(
        context.rootState.main.token,
        userId,
        startDate,
        endDate,
      );
      if (response && response.data) {
        return response.data;
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async dispatchGetProductCostModels(context: MainContext) {
    try {
      const response = await api.getProductCostModels(context.rootState.main.token);
      if (response && response.data) {
        return response.data;
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async dispatchGetCostModels(context: MainContext) {
    try {
      const response = await api.getCostModels(context.rootState.main.token);
      if (response && response.data) {
        return response.data;
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async dispatchGetUserCostModels(context: MainContext) {
    try {
      const response = await api.getUserCostModels(context.rootState.main.token);
      if (response && response.data) {
        return response.data;
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async dispatchCreateCostModel(context: MainContext, payload: ICostModelCreate) {
    try {
      const loadingNotification = { content: "saving", showProgress: true };
      commitAddNotification(context, loadingNotification);
      const response = (
        await Promise.all([
          api.createCostModel(context.rootState.main.token, payload),
          await new Promise<void>((resolve, _) => setTimeout(() => resolve(), 500)),
        ])
      )[0];

      if (response && response.data) {
        commitRemoveNotification(context, loadingNotification);
        commitAddNotification(context, {
          content: "Cost model successfully created",
          color: "success",
        });

        return response.data;
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async dispatchUpdateCostModel(
    context: MainContext,
    payload: { id: number; costModel: ICostModelUpdate },
  ) {
    try {
      const loadingNotification = { content: "saving", showProgress: true };
      commitAddNotification(context, loadingNotification);
      const response = (
        await Promise.all([
          api.updateCostModel(
            context.rootState.main.token,
            payload.id,
            payload.costModel,
          ),
          await new Promise<void>((resolve, _) => setTimeout(() => resolve(), 500)),
        ])
      )[0];
      if (response && response.data) {
        commitRemoveNotification(context, loadingNotification);
        commitAddNotification(context, {
          content: "Cost model successfully updated",
          color: "success",
        });

        return response.data;
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async dispatchDeleteCostModel(context: MainContext, payload: { id: number }) {
    try {
      const loadingNotification = { content: "saving", showProgress: true };
      commitAddNotification(context, loadingNotification);
      const response = (
        await Promise.all([
          api.deleteCostModel(context.rootState.main.token, payload.id),
          await new Promise<void>((resolve, _) => setTimeout(() => resolve(), 500)),
        ])
      )[0];
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, {
        content: "Cost model successfully deleted",
        color: "success",
      });

      return response.data;
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async dispatchCreateProductCostModel(
    context: MainContext,
    payload: IProductCostModelCreate,
  ) {
    try {
      const loadingNotification = { content: "saving", showProgress: true };
      commitAddNotification(context, loadingNotification);
      const response = (
        await Promise.all([
          api.createProductCostModel(context.rootState.main.token, payload),
          await new Promise<void>((resolve, _) => setTimeout(() => resolve(), 500)),
        ])
      )[0];
      if (response && response.data) {
        commitRemoveNotification(context, loadingNotification);
        commitAddNotification(context, {
          content: "Product cost model successfully created",
          color: "success",
        });

        return response.data;
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async dispatchUpdateProductCostModel(
    context: MainContext,
    payload: { id: number; productCostModel: IProductCostModelUpdate },
  ) {
    try {
      const loadingNotification = { content: "saving", showProgress: true };
      commitAddNotification(context, loadingNotification);
      const response = (
        await Promise.all([
          api.updateProductCostModel(
            context.rootState.main.token,
            payload.id,
            payload.productCostModel,
          ),
          await new Promise<void>((resolve, _) => setTimeout(() => resolve(), 500)),
        ])
      )[0];
      if (response && response.data) {
        commitRemoveNotification(context, loadingNotification);
        commitAddNotification(context, {
          content: "Product cost model successfully updated",
          color: "success",
        });

        return response.data;
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async dispatchDeleteProductCostModel(context: MainContext, payload: { id: number }) {
    try {
      const loadingNotification = { content: "saving", showProgress: true };
      commitAddNotification(context, loadingNotification);
      const response = (
        await Promise.all([
          api.deleteProductCostModel(context.rootState.main.token, payload.id),
          await new Promise<void>((resolve, _) => setTimeout(() => resolve(), 500)),
        ])
      )[0];
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, {
        content: "Product cost model successfully deleted",
        color: "success",
      });

      return response.data;
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async dispatchCreateUserCostModel(
    context: MainContext,
    payload: IUserCostModelCreate,
  ) {
    try {
      const loadingNotification = { content: "saving", showProgress: true };
      commitAddNotification(context, loadingNotification);
      const response = (
        await Promise.all([
          api.createUserCostModel(context.rootState.main.token, payload),
          await new Promise<void>((resolve, _) => setTimeout(() => resolve(), 500)),
        ])
      )[0];
      if (response && response.data) {
        commitRemoveNotification(context, loadingNotification);
        commitAddNotification(context, {
          content: "User cost model successfully created",
          color: "success",
        });

        return response.data;
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async dispatchUpdateUserCostModel(
    context: MainContext,
    payload: { id: number; userCostModel: IUserCostModelUpdate },
  ) {
    try {
      const loadingNotification = { content: "saving", showProgress: true };
      commitAddNotification(context, loadingNotification);
      const response = (
        await Promise.all([
          api.updateUserCostModel(
            context.rootState.main.token,
            payload.id,
            payload.userCostModel,
          ),
          await new Promise<void>((resolve, _) => setTimeout(() => resolve(), 500)),
        ])
      )[0];
      if (response && response.data) {
        commitRemoveNotification(context, loadingNotification);
        commitAddNotification(context, {
          content: "User cost model successfully updated",
          color: "success",
        });

        return response.data;
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async dispatchDeleteUserCostModel(context: MainContext, payload: { id: number }) {
    try {
      const loadingNotification = { content: "saving", showProgress: true };
      commitAddNotification(context, loadingNotification);
      const response = (
        await Promise.all([
          api.deleteUserCostModel(context.rootState.main.token, payload.id),
          await new Promise<void>((resolve, _) => setTimeout(() => resolve(), 500)),
        ])
      )[0];
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, {
        content: "User cost model successfully deleted",
        color: "success",
      });

      return response.data;
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async dispatchCreateUserLicenseForUser(
    context: MainContext,
    payload: { license: IUserLicenseCreate; user_id: number },
  ) {
    try {
      const loadingNotification = { content: "Creating license", showProgress: true };
      commitAddNotification(context, loadingNotification);
      const response = (
        await Promise.all([
          api.createUserLicenseForUser(
            context.rootState.main.token,
            payload.user_id,
            payload.license,
          ),
          await new Promise<void>((resolve, _) => setTimeout(() => resolve(), 500)),
        ])
      )[0];
      commitRemoveNotification(context, loadingNotification);
      if (response && response.data) {
        commitAddNotification(context, {
          content: "License successfully created",
          color: "success",
        });
      } else {
        commitAddNotification(context, {
          content: "Error creating license",
          color: "error",
        });
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async dispatchDeleteUserLicenseForUser(
    context: MainContext,
    payload: { user_id: number; stub: string },
  ) {
    try {
      const loadingNotification = { content: "Deleting license", showProgress: true };
      commitAddNotification(context, loadingNotification);
      const response = (
        await Promise.all([
          api.deleteUserLicenseForUser(
            context.rootState.main.token,
            payload.user_id,
            payload.stub,
          ),
          await new Promise<void>((resolve, _) => setTimeout(() => resolve(), 500)),
        ])
      )[0];
      commitRemoveNotification(context, loadingNotification);
      if (response && response.data) {
        commitAddNotification(context, {
          content: "License successfully deleted",
          color: "success",
        });
      } else {
        commitAddNotification(context, {
          content: "Error deleting license",
          color: "error",
        });
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
};

const { dispatch } = getStoreAccessors<AdminState, State>("");

export const dispatchGetUser = dispatch(actions.actionGetUser);
export const dispatchCreateUser = dispatch(actions.actionCreateUser);
export const dispatchGetUsers = dispatch(actions.actionGetUsers);
export const dispatchUpdateUser = dispatch(actions.actionUpdateUser);
export const dispatchGetUserLicensesByUser = dispatch(
  actions.actionGetUserLicensesByUser,
);
export const dispatchGetUsageCostsStatisticsByUser = dispatch(
  actions.actionGetUsageCostsStatisticsByUser,
);
export const dispatchGetComputeJobsByUser = dispatch(
  actions.actionGetComputeJobsByUser,
);
export const dispatchGetProductCostModels = dispatch(
  actions.dispatchGetProductCostModels,
);
export const dispatchGetCostModels = dispatch(actions.dispatchGetCostModels);
export const dispatchGetUserCostModels = dispatch(actions.dispatchGetUserCostModels);
export const dispatchCreateCostModel = dispatch(actions.dispatchCreateCostModel);
export const dispatchUpdateCostModel = dispatch(actions.dispatchUpdateCostModel);
export const dispatchDeleteCostModel = dispatch(actions.dispatchDeleteCostModel);
export const dispatchCreateProductCostModel = dispatch(
  actions.dispatchCreateProductCostModel,
);
export const dispatchUpdateProductCostModel = dispatch(
  actions.dispatchUpdateProductCostModel,
);
export const dispatchDeleteProductCostModel = dispatch(
  actions.dispatchDeleteProductCostModel,
);
export const dispatchCreateUserCostModel = dispatch(
  actions.dispatchCreateUserCostModel,
);
export const dispatchUpdateUserCostModel = dispatch(
  actions.dispatchUpdateUserCostModel,
);
export const dispatchDeleteUserCostModel = dispatch(
  actions.dispatchDeleteUserCostModel,
);
export const dispatchCreateUserLicenseForUser = dispatch(
  actions.dispatchCreateUserLicenseForUser,
);
export const dispatchDeleteUserLicenseForUser = dispatch(
  actions.dispatchDeleteUserLicenseForUser,
);

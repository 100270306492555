import axios from "axios";
import { apiUrl } from "@/env";
import {
  IUserProfile,
  IUserProfileUpdate,
  IUserProfileCreate,
  IUserLicenseCreate,
  IUsageCostsStatistics,
  IUserLicense,
  IProductCostModelCreate,
  ICostModelCreate,
  ICostModelUpdate,
  IUserCostModelCreate,
  IUserCostModelUpdate,
  IProductCostModelUpdate,
} from "./interfaces";

function authHeaders(token: string) {
  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
}

export const api = {
  async logInGetToken(username: string, password: string) {
    const params = new URLSearchParams();
    params.append("username", username);
    params.append("password", password);

    return axios.post(`${apiUrl}/api/v1/login/access-token`, params);
  },
  async getMe(token: string) {
    return axios.get<IUserProfile>(`${apiUrl}/api/v1/users/me`, authHeaders(token));
  },
  async updateMe(token: string, data: IUserProfileUpdate) {
    return axios.put<IUserProfile>(
      `${apiUrl}/api/v1/users/me`,
      data,
      authHeaders(token),
    );
  },
  async getUser(token: string, userId: number) {
    return axios.get<IUserProfile>(
      `${apiUrl}/api/v1/users/${userId}`,
      authHeaders(token),
    );
  },
  async getUsers(token: string) {
    return axios.get<IUserProfile[]>(`${apiUrl}/api/v1/users/`, authHeaders(token));
  },
  async updateUser(token: string, userId: number, data: IUserProfileUpdate) {
    return axios.put(`${apiUrl}/api/v1/users/${userId}`, data, authHeaders(token));
  },
  async createUser(token: string, data: IUserProfileCreate) {
    return axios.post(`${apiUrl}/api/v1/users/`, data, authHeaders(token));
  },
  async passwordRecovery(email: string) {
    return axios.post(`${apiUrl}/api/v1/password-recovery/${email}`);
  },
  async resetPassword(password: string, token: string) {
    return axios.post(`${apiUrl}/api/v1/reset-password/`, {
      new_password: password,
      token,
    });
  },
  async getUserLicenses(token: string) {
    return axios.get<IUserLicense[]>(
      `${apiUrl}/api/v1/userlicenses/me`,
      authHeaders(token),
    );
  },
  async getUserLicensesByUser(token: string, userId: number) {
    return axios.get<IUserLicense[]>(
      `${apiUrl}/api/v1/userlicenses/${userId}`,
      authHeaders(token),
    );
  },
  async createUserLicense(token: string, data: IUserLicenseCreate) {
    return axios.post(`${apiUrl}/api/v1/userlicenses/create`, data, authHeaders(token));
  },
  async deleteUserLicense(token: string, stub: string) {
    return axios.delete(`${apiUrl}/api/v1/userlicenses/${stub}`, authHeaders(token));
  },
  async getUsageCostsStatistics(
    token: string,
    startDate: string,
    endDate: string,
    enduserId: number | null,
    enduserCompanyId: number | null,
  ) {
    return axios.get<IUsageCostsStatistics[]>(`${apiUrl}/api/v1/users/me/usage-costs`, {
      ...authHeaders(token),
      params: {
        start_date: startDate,
        end_date: endDate,
        enduser_id: enduserId,
        enduser_company_id: enduserCompanyId,
      },
    });
  },
  async getUsageCostsStatisticsByUser(
    token: string,
    startDate: string,
    endDate: string,
    userId: number,
  ) {
    return axios.get<IUsageCostsStatistics[]>(
      `${apiUrl}/api/v1/users/${userId}/usage-costs`,
      {
        ...authHeaders(token),
        params: { start_date: startDate, end_date: endDate, user_id: userId },
      },
    );
  },
  async getComputeJobs(
    token: string,
    startDate: string,
    endDate: string,
    enduserId: number | null,
    enduserCompanyId: number | null,
  ) {
    return axios.get(`${apiUrl}/api/v1/computejobs/me`, {
      ...authHeaders(token),
      params: {
        start_date: startDate,
        end_date: endDate,
        enduser_id: enduserId,
        enduser_company_id: enduserCompanyId,
        skip: 0,
        limit: 10000,
      },
    });
  },
  async getComputeJobsByUser(
    token: string,
    userId: number,
    startDate: string,
    endDate: string,
  ) {
    return axios.get(`${apiUrl}/api/v1/computejobs/${userId}`, {
      ...authHeaders(token),
      params: { start_date: startDate, end_date: endDate, skip: 0, limit: 10000 },
    });
  },
  async getProductCostModels(token: string) {
    return axios.get(
      `${apiUrl}/api/v1/billing/product-cost-models`,
      authHeaders(token),
    );
  },
  async getCostModels(token: string) {
    return axios.get(`${apiUrl}/api/v1/billing/cost-models`, authHeaders(token));
  },
  async getUserCostModels(token: string) {
    return axios.get(`${apiUrl}/api/v1/billing/user-cost-models`, authHeaders(token));
  },
  async createCostModel(token: string, data: ICostModelCreate) {
    return axios.post(`${apiUrl}/api/v1/billing/cost-models`, data, authHeaders(token));
  },
  async updateCostModel(token: string, id: number, data: ICostModelUpdate) {
    return axios.put(
      `${apiUrl}/api/v1/billing/cost-models/${id}`,
      data,
      authHeaders(token),
    );
  },
  async deleteCostModel(token: string, id: number) {
    return axios.delete(
      `${apiUrl}/api/v1/billing/cost-models/${id}`,
      authHeaders(token),
    );
  },
  async createProductCostModel(token: string, data: IProductCostModelCreate) {
    return axios.post(
      `${apiUrl}/api/v1/billing/product-cost-models`,
      data,
      authHeaders(token),
    );
  },
  async updateProductCostModel(
    token: string,
    id: number,
    data: IProductCostModelUpdate,
  ) {
    return axios.put(
      `${apiUrl}/api/v1/billing/product-cost-models/${id}`,
      data,
      authHeaders(token),
    );
  },
  async deleteProductCostModel(token: string, id: number) {
    return axios.delete(
      `${apiUrl}/api/v1/billing/product-cost-models/${id}`,
      authHeaders(token),
    );
  },
  async createUserCostModel(token: string, data: IUserCostModelCreate) {
    return axios.post(
      `${apiUrl}/api/v1/billing/user-cost-models`,
      data,
      authHeaders(token),
    );
  },
  async updateUserCostModel(token: string, id: number, data: IUserCostModelUpdate) {
    return axios.put(
      `${apiUrl}/api/v1/billing/user-cost-models/${id}`,
      data,
      authHeaders(token),
    );
  },
  async deleteUserCostModel(token: string, id: number) {
    return axios.delete(
      `${apiUrl}/api/v1/billing/user-cost-models/${id}`,
      authHeaders(token),
    );
  },
  async createUserLicenseForUser(
    token: string,
    userId: number,
    data: IUserLicenseCreate,
  ) {
    return axios.post(
      `${apiUrl}/api/v1/userlicenses/${userId}/create`,
      data,
      authHeaders(token),
    );
  },
  async deleteUserLicenseForUser(token: string, userId: number, stub: string) {
    return axios.delete(
      `${apiUrl}/api/v1/userlicenses/${userId}/${stub}`,
      authHeaders(token),
    );
  },
};

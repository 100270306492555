import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: "mdi",
  },
  theme: {
    themes: {
      light: {
        primary: "#5F8D4E", // Your primary color 5F8D4E
        secondary: "#3B5249", // Your secondary color
        success: "#749F82",
      },
    },
  },
});
